import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import CurrencyValue from 'ui-component/CurrencyValue';
import { useSelector } from 'react-redux';


const OpenSuppliersWidgetComponent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    
    const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
    
    const getData = async () => {
        setIsLoading(true);
        api
            .getDashboardOpenSuppliers()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    const formattedData = res.data.map((item) => {
                        return {
                            ...item,
                            name: item.name,
                            total: parseFloat(item.total).toFixed(2)
                        }   
                    })
                    .sort((a, b) => parseFloat(b.total) - parseFloat(a.total)); 
                    setData(formattedData);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setData([]);
                console.error('Failed to retrieve data:', error);
            });
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        console.log('selectedCurrency', selectedCurrency)
    }, [selectedCurrency])

    return (
        <>
            <div className="card" style={{ height: '200px' }}>
                <div className="card-body fw-600" style={{ padding: '15px', zIndex: '1000', zIndex: '1' }}>
                    <div className={`text-lg`}>
                        Open Suppliers
                    </div>
                    <div className="overflow-y-auto custom-scrollbar" style={{ maxHeight: '135px', margin: '10px 0' }}>
                        {
                            isLoading ? (
                                <>
                                    {
                                        [...Array(15)].map((e, i) => (
                                            <>
                                                <div key={i} className="row" style={{ padding: '5px 0' }}>
                                                    <div className="col-12">
                                                        <Skeleton variant="rounded" width={'100%'} height={15} className={`rounded-full`} />
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        data?.length > 0 ? (
                                            <>
                                                {
                                                    data?.map((item, index) => (
                                                        <>
                                                            {
                                                                item?.total > 0 && (
                                                                    <>
                                                                        <div key={index} className="w-full flex justify-between items-center" style={{ padding: '2px 0' }}>
                                                                            <div className="font-normal" style={{ width: '55%' }}>
                                                                                {item?.name}
                                                                            </div>
                                                                            <div className="ml-auto text-right" style={{ marginRight: '10px', width: '45%' }}>
                                                                                <CurrencyValue currencyObject={ item?.converted_prices } />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className="row" style={{ padding: '5px 0' }}>
                                                    <div className="col-12 text-gray-500">
                                                        No data found        
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    )
}


export default OpenSuppliersWidgetComponent;
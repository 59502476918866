import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';


const InventoryValuesWidgetComponent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    // const [pagination, setPagination] = useState([]);

    const getData = async () => {
        setIsLoading(true);
        api
            .getDashboardInventoryValues()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setData(res.data);
                    // setPagination(res.data);
                } else {
                    setData([]);
                }
                // isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setData([]);
                console.error('Failed to retrieve data:', error);
            });
    }

    useEffect(() => {
        getData()
    }, [])



    return (
        <>
            <div className="card" style={{ height: '100px' }}>
                <div className="card-body fw-600" style={{ padding: '15px', zIndex: '1000', zIndex: '1' }}>
                    <div className={`text-lg w-full flex justify-center items-center`}>
                        Current Inventory Values
                    </div>
                    <div className="row" >
                        <div className="col-12 fw-700 text-center text-2xl text-gray-custom-1 w-full text-center flex justify-center">
                            {
                                isLoading ? (
                                    <>
                                        <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                            <Skeleton variant="rounded" width={'50%'} height={15} className={`rounded-full`} />
                                        </div>
                                    </>
                                ): (
                                    <>
                                       $ { data?.total_price !== '0.00' ? `${parseFloat(data?.total_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : '0.00' }     
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-12 text-center text-base text-success fw-600">
                            {
                                isLoading ? (
                                    <>
                                        <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                            <Skeleton variant="rounded" width={'80px'} height={15} className={`rounded-full`} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        { parseInt(data?.total_products).toLocaleString() } products
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default InventoryValuesWidgetComponent;
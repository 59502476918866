import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios'

export default {
	
    getDashboardTopProducts() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/top-products`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getDashboardInventoryValues() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/inventory-values`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getDashboardProductComparison() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/product-comparison`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getDashboardSalesOrders() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/salesorders`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    getDashboardOpenClients() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/clients`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },
    getDashboardTopClients() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/top-clients`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getDashboardOpenSuppliers() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/suppliers`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

    getDashboardTopSuppliers() {
        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(`${base}/api/dashboard/top-suppliers`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
    },

}

// import { useSelector } from 'react-redux'

import 'assets/scss/admin.min.css';
import 'assets/scss/fontawesome-free/css/all.min.css';
import 'assets/scss/dashboard.scss';
import 'assets/scss/dashboard-new.scss';
import 'assets/scss/margins-paddings.scss';
import 'assets/scss/drawer.scss';
import 'assets/scss/breadcrumbs.scss';
import 'assets/scss/badges.scss';
import 'assets/scss/timeline.scss';
import 'assets/scss/custom.scss';
import 'assets/scss/tailwindcss-classess.scss';
import 'api';

// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeMasterStatuses, storeStatusTypes } from 'store/reducers/mainSlice';
import { useLocation } from 'react-router-dom';

import packageJson from '../package.json';

// ==============================|| APP ||============================== //

const App = () => {
	const dispatch = useDispatch();
	const masterStatuses = useSelector((state) => state.main.masterStatuses);
	const location = useLocation();
	useEffect(() => {

		if (masterStatuses) {
			document.getElementById('appLoader').classList.add('hide-loader');
		}
	}, [masterStatuses]);

	useEffect(() => {
	  if (!location.pathname.includes('client')) {
	    api.getAllstatus().then((res) => {
	      const tempStatus = res?.data?.map((item) => ({ id: item.id, name: item.name, type: item.type }));
	      dispatch(storeMasterStatuses(tempStatus));
	    });

	    api.getAllStatusTypes().then((res) => {
	      const tempStatus = res?.data?.map((item) => ({ id: item.id, name: item.type }));
	      dispatch(storeStatusTypes(tempStatus));
	    });

	    console.log(`ProjectMix v${packageJson.version} 😎🎉`);
	  }else{
		document.getElementById('appLoader').classList.add('hide-loader');
	  }
	}, [location]);

	return (
		<NavigationScroll>
			<Routes />
		</NavigationScroll>
	);
};

export default App;

import * as React from 'react';
import SalesDashboard from './role1.js';
import AdminDashboard from './role2.js';
import DashboardComponent from './components/DashboardComponent.jsx'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


// ... other code ...

const Dashboard = () => {
	const [userRole, setUserRole] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		api.getDashboardRole().then((res) => {
			setIsLoading(false);
			setUserRole(res.role);
		});
	}, []);


	if (isLoading) {
		return <div>Loading...</div>;
	}

	const role = String(userRole);

		
	return <DashboardComponent />;
	// if (role === '3' || role === '4') {
	//   return <SalesDashboard />;
	// } else if (role === '1' || role === '2' || role === '9') {
	//   return <AdminDashboard />;
	// }

	return <div>Unknown role</div>;
};

export default Dashboard;
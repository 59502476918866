import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { ArrowUpRight, ArrowDownRight, MinusIcons } from 'ui-component/custom-icons/icons';


const ProductComparisonWidgetComponent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    // const [pagination, setPagination] = useState([]);

    const getData = async () => {
        setIsLoading(true);
        api
            .getDashboardProductComparison()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setData(res.data);
                    // setPagination(res.data);
                } else {
                    setData([]);
                }
                // isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setData([]);
                console.error('Failed to retrieve data:', error);
            });
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <>
            <div className="card" style={{ height: '200px' }}>
                <div className="card-body w-full flex justify-center items-center" style={{ padding: '20px', zIndex: '1000', zIndex: '1' }}>
                    <div className="row  w-full">
                        <div className="col-6">
                            <div className="row" style={{ marginBottom: '8px'}}>
                                <div className="col-12 flex justify-center items-center w-full">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center">
                                                    <Skeleton
                                                        variant="rounded"
                                                        width={'50px'}
                                                        height={'50px'}
                                                        className={`rounded-full`}
                                                        style={{ borderRadius: '64px' }}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`rounded-full ${data?.total_sold > 0 ? 'bg-green-100 text-green-500' : 'bg-red-100  text-red-500' }`}
                                                    style={{
                                                        padding: '15px 17px'
                                                    }}
                                                >
                                                    {
                                                        data?.total_sold > 0 ? (
                                                            <ArrowUpRight height={'15px'} width={'15px'} />
                                                        ) : (
                                                            <MinusIcons height={'15px'} width={'15px'} />   
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '5px'}}>
                                <div className="col-12 text-center fw-500">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'100px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                Product Sold
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '5px'}}>
                                <div className="col-12 text-center text-2xl fw-700 text-gray-custom-1">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'80px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                { data?.total_sold > 0 ? `${parseInt(data?.total_sold).toLocaleString()}` : '0' }    
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="col-12 text-center text-success"
                                >
                                    
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'90px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                + 2% last month
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row" style={{ marginBottom: '8px'}}>
                                <div className="col-12 flex justify-center items-center w-full">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center">
                                                    <Skeleton
                                                        variant="rounded"
                                                        width={'50px'}
                                                        height={'50px'}
                                                        className={`rounded-full`}
                                                        style={{ borderRadius: '64px' }}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`rounded-full ${data?.total_returns > 0 ? 'bg-red-100  text-red-500' : 'bg-gray-100 text-gray-500' }`}
                                                    style={{
                                                        padding: '15px 17px'
                                                    }}
                                                >
                                                    {
                                                        data?.total_returns > 0 ? (
                                                            <ArrowUpRight height={'15px'} width={'15px'} />
                                                        ) : (
                                                            <MinusIcons height={'15px'} width={'15px'} />   
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '5px'}}>
                                <div className="col-12 text-center fw-500">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'100px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                Total Returns
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '5px'}}>
                                <div className="col-12 text-center text-2xl fw-700 text-gray-custom-1">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'80px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                { data?.total_returns > 0 ? `${parseInt(data?.total_returns).toLocaleString()}` : '0' }    
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center text-danger">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full text-center flex justify-center" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'90px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                - 10% last month
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ProductComparisonWidgetComponent;
import React, { useState } from 'react';
// import dynamic from "next/dynamic";
// import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";


const GrossProfitWidgetComponent = () => {

    return (
        <>
            <div className="card" style={{ height: '200px' }}>
                <div className="card-body" style={{ padding: '20px', zIndex: '1000', zIndex: '1' }}>
                    Gross Profit
                </div>
            </div>
        </>
    )
}


export default GrossProfitWidgetComponent;
import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { ArrowUpRight, ArrowDownRight, MinusIcons } from 'ui-component/custom-icons/icons';


const SalesOrderWidgetComponent = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0);

    const getData = async () => {
        setIsLoading(true);
        api
            .getDashboardSalesOrders()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    const formatData = res.data.map((item) => {
                        return {
                            ...item,
                            total: parseFloat(item.total['usd']).toFixed(2)
                        }
                    })
                    const totalSum = formatData.reduce((sum, item) => sum + parseFloat(item.total), 0).toFixed(2);
                    setData(formatData);
                    setTotal(totalSum);
                } else {
                    setData([]);
                }
                // isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setData([]);
                console.error('Failed to retrieve data:', error);
            });
    }

    useEffect(() => {
        getData()
    }, [])
    

    return (
        <>
            <div className="card" style={{ height: '100px' }}>
                <div className="card-body" style={{ padding: '15px', zIndex: '1000', zIndex: '1' }}>
                    <div className='row'>
                        <div className="col-12 fw-700 text-gray-custom-1 w-full items-center flex gap-3">
                            <div>
                                {
                                    isLoading ? (
                                        <>
                                            <div className="w-full text-center flex justify-center">
                                                <Skeleton
                                                    variant="rounded"
                                                    width={'50px'}
                                                    height={'50px'}
                                                    className={`rounded-full`}
                                                    style={{ borderRadius: '64px' }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={`rounded-full ${total > 0 ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-gray-500' } `}
                                                style={{
                                                    padding: '15px 17px'
                                                }}
                                            >
                                                {
                                                    total > 0 ? (
                                                        <ArrowUpRight height={'15px'} width={'15px'} />
                                                    ) : (
                                                        <MinusIcons height={'15px'} width={'15px'} />   
                                                    )
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-12 text-left">
                                    Sales Orders
                                </div>
                                <div className="col-12 text-left fw-700 text-2xl text-gray-custom-1 w-full flex">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full flex" style={{ margin: '5px auto' }}>
                                                    <Skeleton variant="rounded" width={'100px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                $ { parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }
                                            </>
                                        )
                                    }
                                    
                                </div>
                                <div className="col-12 text-left text-success fw-600">
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="w-full flex" >
                                                    <Skeleton variant="rounded" width={'100px'} height={15} className={`rounded-full`} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                + 3.49%
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}


export default SalesOrderWidgetComponent;
import React, { useState } from 'react';
// import dynamic from "next/dynamic";
// import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";


const SupplierReturnsWidgetComponent = () => {

    return (
        <>
            <div className="card" style={{ height: '200px' }}>
                <div className="card-body" style={{ padding: '15px', zIndex: '1000', zIndex: '1' }}>
                    <div className="row">
                        <div className="col-12 fw-700">
                            Returns per Supplier
                        </div>
                        <div
                            className="col-12 text-center text-gray-600 flex w-full justify-center items-center"
                            style={{ height: '150px' }}
                        >
                            Widget not available
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SupplierReturnsWidgetComponent;
import React, { useState } from 'react';
// import dynamic from "next/dynamic";
// import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";


const WotmWidgetComponent = () => {

    return (
        <>
            <div className="card" style={{ height: '200px' }}>
                <div className="card-body" style={{ padding: '20px', zIndex: '1000', zIndex: '1' }}>
                    Warrior of the month
                </div>
            </div>
        </>
    )
}


export default WotmWidgetComponent;